import { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import styles from 'styles/components/template.module.scss'
import DataLoad from 'components/Init/DataLoad'
import PageHedding from 'components/PageHedding/PageHedding'
import InputTextWithLabel from 'components/Form/WithLabel/InputText'
import InputTextAndUnitWithLabel from 'components/Form/WithLabel/InputTextAndUnit'
import InputTextAndUnitDoubleWithLabel from 'components/Form/WithLabel/InputTextAndUnitDouble'
import SubmitButton from 'components/Button/SubmitButton'
import AddFormSupplierInformation, {
  AddFormInternalCapacityInfo,
} from 'components/Form/AddForm/SupplierInformation'
import {
  getMaterialById,
  clearMaterial,
  getMaterials,
  MaterialType,
} from 'reducks/material/slice'
import {
  InternalCapacityType,
  SupplierType,
  createMaterialCost,
  updateMaterialCost,
  clearMaterialCost,
  getMaterialCostByMaterialId,
  MaterialCostType,
} from 'reducks/materialCost/slice'
import { DataType } from 'reducks/business/slice'
import { setIsLoading } from 'reducks/loading/slice'
import slack from 'util/slack'
import { RootState } from 'reducks/reducers'
import { httpsCallable } from 'firebase/functions'
import { functions } from 'fb/index'

interface SupplierInfo {
  internalCapacity: InternalCapacityInfo | null
  internalCapacityManualInput: string | null
  internalCapacityCount: string
  internalCapacityUnit: string
  numberItem: string
  numberItemUnit: string
  supplierCategory: string
  supplierCompanyName: string
  supplierBranchName: string
  costPrice: string
  taxIncludedCostPrice: string
  tax: number
  memo: string
  usedCosting: boolean
  unitAdjustment: number
}

interface InternalCapacityInfo {
  internalCapacityCount: string
  internalCapacityUnit: string
  jancode: string | null
  numberItem: string | null
  numberItemUnit: string | null
  originName: string | null
}

const MaterialOperationTemplate: FC = () => {
  const dispatch = useDispatch<any>()
  const navigate = useNavigate()
  const location = useLocation()
  dayjs.locale('ja')
  const path = location.pathname
  const materialId = path.split('/material/')[1].split('/cost')[0]
    ? path.split('/material/')[1].split('/cost')[0]
    : null
  const [materialCostId, setMaterialCostId] = useState<string | null>(null)
  // 基本情報
  const [productName, setProductName] = useState<string>('')
  const [supplierInfos, setSupplierInfos] = useState<SupplierInfo[]>([
    {
      internalCapacity: null,
      internalCapacityManualInput: null,
      internalCapacityCount: '1',
      internalCapacityUnit: '',
      numberItem: '1',
      numberItemUnit: '個',
      supplierCategory: '',
      supplierCompanyName: '',
      supplierBranchName: '',
      costPrice: '',
      taxIncludedCostPrice: '',
      tax: 8,
      memo: '',
      usedCosting: true,
      unitAdjustment: 1,
    },
  ])
  const [ediblePart, setEdiblePart] = useState<string>('')
  const [inEdiblePart, setInEdiblePart] = useState<string>('')
  const [yieldRate, setYieldRate] = useState<string>('100')
  const [author, setAuthor] = useState<string>('')
  const [updatedAt, setUpdatedAt] = useState<string>('')
  const [processSupplierInfoValidate, setProcessSupplierInfoValidate] =
    useState<boolean>(false)

  // 基本情報
  const handleSupplierInfos = (supplierInfos: SupplierInfo[]) => {
    setSupplierInfos(supplierInfos)
  }
  const handleYieldRate = (yieldRate: string) => {
    // 半角数字に変換
    yieldRate = yieldRate.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(yieldRate) && Number(yieldRate) !== 0) {
      return
    }
    setYieldRate(yieldRate)
    setYieldRateErrorText(null)
  }
  const handleEdiblePart = (ediblePart: string) => {
    // 半角数字に変換
    ediblePart = ediblePart.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(ediblePart) && Number(ediblePart) !== 0) {
      return
    }
    setEdiblePart(ediblePart)
  }
  const handleInEdiblePart = (inEdiblePart: string) => {
    // 半角数字に変換
    inEdiblePart = inEdiblePart.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(inEdiblePart) && Number(inEdiblePart) !== 0) {
      return
    }
    setInEdiblePart(inEdiblePart)
  }
  const handleAuthor = (author: string) => {
    setAuthor(author)
  }

  // Validation
  const [yieldRateErrorText, setYieldRateErrorText] = useState<string | null>(
    null,
  )
  const [internalCapacityErrorFlg, setInternalCapacityErrorFlg] =
    useState<boolean>(false)
  const [supplierCategoryErrorFlg, setSupplierCategoryErrorFlg] =
    useState<boolean>(false)
  const [supplierCompanyNameErrorFlg, setSupplierCompanyNameErrorFlg] =
    useState<boolean>(false)
  const [supplierBranchNameErrorFlg, setSupplierBranchNameErrorFlg] =
    useState<boolean>(false)
  const [costPriceErrorFlg, setCostPriceErrorFlg] = useState<boolean>(false)
  const [taxErrorFlg, setTaxErrorFlg] = useState<boolean>(false)
  const [internalCapacityCountErrorFlg, setInternalCapacityCountErrorFlg] =
    useState<boolean>(false)

  const handleInternalCapacityError = (errorFlg: boolean) => {
    setInternalCapacityErrorFlg(errorFlg)
  }

  const handleSupplierCategoryError = (errorFlg: boolean) => {
    setSupplierCategoryErrorFlg(errorFlg)
  }

  const handleSupplierCompanyNameError = (errorFlg: boolean) => {
    setSupplierCompanyNameErrorFlg(errorFlg)
  }

  const handleSupplierBranchNameError = (errorFlg: boolean) => {
    setSupplierBranchNameErrorFlg(errorFlg)
  }

  const handleCostPriceError = (errorFlg: boolean) => {
    setCostPriceErrorFlg(errorFlg)
  }

  const handleTaxError = (errorFlg: boolean) => {
    setTaxErrorFlg(errorFlg)
  }

  const handleInternalCapacityCountError = (errorFlg: boolean) => {
    setInternalCapacityCountErrorFlg(errorFlg)
  }

  const handleProcessSupplierInfoValidate = (
    processSupplierInfoValidate: boolean,
  ) => {
    setProcessSupplierInfoValidate(processSupplierInfoValidate)
  }

  const validation = () => {
    let isError = false
    if (!yieldRate) {
      isError = true
      setYieldRateErrorText('入力されておりません')
    }
    if (yieldRate && (Number(yieldRate) <= 0 || Number(yieldRate) > 100)) {
      isError = true
      setYieldRateErrorText('1〜100の範囲で入力してください')
    }
    supplierInfos.forEach((supplierInfo) => {
      // 必須チェックのみ行う
      if (
        !supplierInfo.supplierCategory ||
        !supplierInfo.supplierCompanyName ||
        !supplierInfo.costPrice
      ) {
        setProcessSupplierInfoValidate(true)
        isError = true
      }
    })
    if (internalCapacityErrorFlg) {
      isError = true
    }
    if (supplierCategoryErrorFlg) {
      isError = true
    }
    if (supplierCompanyNameErrorFlg) {
      isError = true
    }
    if (supplierBranchNameErrorFlg) {
      isError = true
    }
    if (costPriceErrorFlg) {
      isError = true
    }
    if (taxErrorFlg) {
      isError = true
    }
    if (internalCapacityCountErrorFlg) {
      isError = true
    }
    if (isError) {
      window.scrollTo(0, 0)
      return false
    }
    return true
  }

  const { staff, adminShopId } = useSelector(
    (state: RootState) => state.staffSlice,
  )
  const handleSubmit = async (to: string) => {
    if (!materialId) {
      return
    }
    const is_valid = validation()
    if (!is_valid) {
      return
    }
    dispatch(setIsLoading({ isLoading: true }))

    const supplieries: SupplierType[] = []
    await Promise.all(
      supplierInfos.map(async (supplierInfo) => {
        const internalCapacityInput: InternalCapacityType | null =
          supplierInfo.internalCapacity === null ||
          Number(supplierInfo.internalCapacity.internalCapacityCount) === 0
            ? null
            : {
                internal_capacity_count: Number(
                  supplierInfo.internalCapacity.internalCapacityCount,
                ),
                internal_capacity_unit:
                  supplierInfo.internalCapacity.internalCapacityUnit,
                jancode: supplierInfo.internalCapacity.jancode
                  ? supplierInfo.internalCapacity.jancode
                  : null,
                number_item: supplierInfo.internalCapacity.numberItem
                  ? Number(supplierInfo.internalCapacity.numberItem)
                  : null,
                number_item_unit: supplierInfo.internalCapacity.numberItemUnit
                  ? supplierInfo.internalCapacity.numberItemUnit
                  : '個',
                origin_name: supplierInfo.internalCapacity.originName
                  ? supplierInfo.internalCapacity.originName
                  : null,
              }
        supplieries.push({
          internal_capacity: internalCapacityInput,
          internal_capacity_manual_input:
            supplierInfo.internalCapacityManualInput,
          internal_capacity_count: Number(supplierInfo.internalCapacityCount),
          internal_capacity_unit: supplierInfo.internalCapacityUnit,
          number_item: supplierInfo.numberItem
            ? Number(supplierInfo.numberItem)
            : null,
          number_item_unit: supplierInfo.numberItemUnit
            ? supplierInfo.numberItemUnit
            : '個',
          supplier_category: supplierInfo.supplierCategory,
          supplier_company_name: supplierInfo.supplierCompanyName,
          supplier_branch_name: supplierInfo.supplierBranchName,
          cost_price: Number(supplierInfo.costPrice),
          tax: supplierInfo.tax,
          memo: supplierInfo.memo,
          used_costing: supplierInfo.usedCosting,
        })
      }),
    )

    if (materialCostId) {
      // Firestoreを更新
      await dispatch(
        updateMaterialCost({
          id: materialCostId,
          material_id: materialId,
          suppliers: supplieries,
          yield_rate: Number(yieldRate),
          edible_part: Number(ediblePart),
          in_edible_part: Number(inEdiblePart),
          author: author,
        }),
      )
    } else {
      // Firestoreに登録
      await dispatch(
        createMaterialCost({
          material_id: materialId,
          suppliers: supplieries,
          yield_rate: Number(yieldRate),
          edible_part: Number(ediblePart),
          in_edible_part: Number(inEdiblePart),
          author: author,
        }),
      )
    }

    // 裏貼りシールステータスと原価を更新する
    const updateBackSealStatusAndCostDaseruno = httpsCallable(
      functions,
      'updateBackSealStatusAndCostDaseruno',
    )
    await updateBackSealStatusAndCostDaseruno({
      docId: materialId,
      dataType: DataType.MATERIAL,
      shopId: staff?.shopId,
    })
    // 未登録の内容量があればSlack送信
    const materialPayload = await dispatch(getMaterialById({ id: materialId }))
    const material: MaterialType = materialPayload.payload
    slack.internalCapacityApply(
      supplieries,
      staff && staff.storeName
        ? staff.storeName
        : staff && staff.shopName
        ? staff.shopName
        : '',
      material.product_name,
    )
    await dispatch(clearMaterialCost())
    await dispatch(getMaterials())

    navigate(to)
    window.scrollTo(0, 0)
    dispatch(setIsLoading({ isLoading: false }))
  }

  // const { material } = useSelector((state: RootState) => state.materialSlice)
  // const { materialCost } = useSelector(
  //   (state: RootState) => state.materialCostSlice,
  // )
  const setMaterialCostInfo = useCallback(
    async (materialId: string | null) => {
      if (!staff) {
        navigate('/initload?path=' + path)
        window.scrollTo(0, 0)
        return
      }
      // 使用できないプラン or スーパー管理者じゃなければ使用できない
      if (
        (staff?.isAdmin === true && adminShopId) ||
        (staff?.isAdmin === false && staff.isStandardPlan === true)
      ) {
        //
      } else {
        navigate('/initload')
        window.scrollTo(0, 0)
      }
      await dispatch(clearMaterial())
      if (materialId) {
        const materialPayload = await dispatch(
          getMaterialById({ id: materialId }),
        )
        const materialCostPayload = await dispatch(
          getMaterialCostByMaterialId({ id: materialId }),
        )
        const material: MaterialType = materialPayload.payload
        const materialCost: MaterialCostType = materialCostPayload.payload
        if (material) {
          const addFormInternalCapacityInfos: AddFormInternalCapacityInfo[] = []
          if (
            material.internal_capacity &&
            material.internal_capacity.length > 0
          ) {
            material.internal_capacity.forEach((internalCapacity) => {
              // 内容量が入力されていなければ選択肢に出てこない
              if (
                !internalCapacity ||
                !internalCapacity.internal_capacity_count ||
                !internalCapacity.number_item
              ) {
                return
              }
              // 入数の単位がない場合は「個」として取り扱う
              const numberItemUnit = internalCapacity.number_item_unit
                ? internalCapacity.number_item_unit
                : '個'
              addFormInternalCapacityInfos.push({
                internalCapacityCount: String(
                  internalCapacity.internal_capacity_count,
                ),
                internalCapacityUnit: internalCapacity.internal_capacity_unit,
                numberItem: String(internalCapacity.number_item),
                numberItemUnit: numberItemUnit,
                jancode: internalCapacity.jancode
                  ? internalCapacity.jancode
                  : null,
                originName: internalCapacity.origin_name
                  ? internalCapacity.origin_name
                  : null,
              })
            })
          }
          setProductName(material.product_name)
        }
        if (materialCost) {
          setMaterialCostId(materialCost.id)
          const suppliers: SupplierInfo[] = []
          materialCost.suppliers.forEach((supplier) => {
            suppliers.push({
              internalCapacity:
                supplier.internal_capacity === null
                  ? null
                  : {
                      internalCapacityCount: String(
                        supplier.internal_capacity.internal_capacity_count,
                      ),
                      internalCapacityUnit:
                        supplier.internal_capacity.internal_capacity_unit,
                      jancode: supplier.internal_capacity.jancode
                        ? supplier.internal_capacity.jancode
                        : null,
                      numberItem: supplier.internal_capacity.number_item
                        ? String(supplier.internal_capacity.number_item)
                        : null,
                      numberItemUnit: supplier.internal_capacity
                        .number_item_unit
                        ? supplier.internal_capacity.number_item_unit
                        : '個',
                      originName: supplier.internal_capacity.origin_name
                        ? supplier.internal_capacity.origin_name
                        : null,
                    },
              internalCapacityManualInput:
                supplier.internal_capacity_manual_input,
              internalCapacityCount: supplier.internal_capacity_count
                ? String(supplier.internal_capacity_count)
                : supplier.internal_capacity &&
                  supplier.internal_capacity.internal_capacity_count
                ? String(supplier.internal_capacity.internal_capacity_count)
                : '',
              internalCapacityUnit: supplier.internal_capacity_unit
                ? supplier.internal_capacity_unit
                : supplier.internal_capacity &&
                  supplier.internal_capacity.internal_capacity_unit
                ? supplier.internal_capacity.internal_capacity_unit
                : 'g',
              numberItem: supplier.number_item
                ? String(supplier.number_item)
                : supplier.internal_capacity &&
                  supplier.internal_capacity.number_item
                ? String(supplier.internal_capacity.number_item)
                : '',
              numberItemUnit: supplier.number_item_unit
                ? supplier.number_item_unit
                : supplier.internal_capacity &&
                  supplier.internal_capacity.number_item_unit
                ? supplier.internal_capacity.number_item_unit
                : '個',
              supplierCategory: supplier.supplier_category,
              supplierCompanyName: supplier.supplier_company_name,
              supplierBranchName: supplier.supplier_branch_name,
              costPrice: String(supplier.cost_price),
              taxIncludedCostPrice: '',
              tax: supplier.tax,
              memo: supplier.memo,
              usedCosting: supplier.used_costing,
              unitAdjustment:
                supplier.internal_capacity_unit === 'kg' ||
                supplier.internal_capacity_unit === 'L'
                  ? 1000
                  : 1,
            })
          })
          setSupplierInfos(suppliers)
          setYieldRate(
            materialCost.yield_rate ? String(materialCost.yield_rate) : '100',
          )
          setEdiblePart(
            materialCost.edible_part ? String(materialCost.edible_part) : '',
          )
          setInEdiblePart(
            materialCost.in_edible_part
              ? String(materialCost.in_edible_part)
              : '',
          )
          setAuthor(materialCost.author)
          setUpdatedAt(
            materialCost.updated_at
              ? dayjs(materialCost.updated_at.toDate())
                  .format('YYYY-MM-DD HH:mm:ss')
                  .toString()
              : '',
          )
        }
      }
    },
    [materialId],
  )

  useEffect(() => {
    setMaterialCostInfo(materialId)
  }, [])

  return (
    <div className={styles.container}>
      <DataLoad />
      <PageHedding title="原価情報" to="/material" />
      <div className={styles.bread_add_recipe}>
        <div className={styles.bread_add_recipe_section}>{productName}</div>
      </div>
      <AddFormSupplierInformation
        formInfos={supplierInfos}
        handleSupplierInfos={handleSupplierInfos}
        handleInternalCapacityError={handleInternalCapacityError}
        handleSupplierCategoryError={handleSupplierCategoryError}
        handleSupplierCompanyNameError={handleSupplierCompanyNameError}
        handleSupplierBranchNameError={handleSupplierBranchNameError}
        handleCostPriceError={handleCostPriceError}
        handleTaxError={handleTaxError}
        handleInternalCapacityCountError={handleInternalCapacityCountError}
        processValidate={processSupplierInfoValidate}
        handleProcessSupplierInfoValidate={handleProcessSupplierInfoValidate}
        yieldRate={Number(yieldRate)}
      />
      <div className={styles.section}></div>
      <div className={styles.material_cost_flex}>
        <InputTextAndUnitWithLabel
          required={true}
          labelName="可食部率（歩留率）"
          description="皮を剥いて使う農産物やオイル漬け等、製品の内容量に対して使用できる部分（可食部）の割合を原価で考慮したい場合に記載してください。<br>例）りんご、オリーブオイル漬"
          line={2}
          type="text"
          size="minimum"
          value={yieldRate}
          placeholder="0"
          margin="min"
          labelSize="large"
          unit="%"
          errorText={yieldRateErrorText}
          onChange={(event) => handleYieldRate(event.target.value)}
        />
        <InputTextAndUnitDoubleWithLabel
          required={false}
          labelName="可/不可食部から可食部率を計算する"
          labelSize="large"
          description=""
          line={2}
          type="text"
          size="minimum"
          margin="min"
          value={ediblePart}
          unitDescription="(可食部)"
          placeholder="0"
          unit="g"
          value2={inEdiblePart}
          unitDescription2="(不可食部)"
          placeholder2="0"
          unit2="g"
          onChange={(event) => handleEdiblePart(event.target.value)}
          onChange2={(event) => handleInEdiblePart(event.target.value)}
        />
        <div
          style={{
            marginLeft: 36,
          }}
        ></div>
        <div
          style={{
            marginTop: 16,
          }}
        >
          <SubmitButton
            label="計算する"
            color="light_gray"
            size="rectangle_small"
            icon="none"
            onClick={() =>
              handleYieldRate(
                String(
                  (
                    (Number(ediblePart) /
                      (Number(ediblePart) + Number(inEdiblePart))) *
                    100
                  ).toFixed(0),
                ),
              )
            }
          />
        </div>
      </div>
      <div className={styles.material_cost_flex}>
        <InputTextWithLabel
          required={false}
          labelName="登録者"
          description=""
          line={1}
          type="text"
          size="semimiddle2"
          value={author}
          placeholder="ヤマダタロウ"
          margin="min"
          labelSize="small"
          onChange={(event) => handleAuthor(event.target.value)}
        />
        {materialId ? (
          <>
            <InputTextWithLabel
              required={false}
              labelName="更新日"
              description=""
              line={1}
              type="text"
              size="semimiddle2"
              value={updatedAt}
              placeholder=""
              margin="min"
              labelSize="small"
              readOnly={true}
            />
          </>
        ) : (
          ''
        )}
      </div>

      <div className={styles.material_submit}>
        <SubmitButton
          label="キャンセル"
          color="orange_outlined"
          size="large"
          icon="none"
          onClick={() => navigate('/material')}
        />
        <span className={styles.material_submit_spacer}></span>
        <SubmitButton
          label="原価情報を保存する"
          color="orange"
          size="large"
          icon="none"
          onClick={() => handleSubmit('/material')}
        />
      </div>
    </div>
  )
}

export default MaterialOperationTemplate
