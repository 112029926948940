import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  Slice,
} from '@reduxjs/toolkit'
import format from 'date-fns/format'
import { db, storage } from 'fb/index'
import {
  collection,
  query,
  where,
  getDocs,
  DocumentReference,
  Timestamp,
  getDoc,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  documentId,
  orderBy,
} from 'firebase/firestore'
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { AppDispatch } from 'index'
import { RootState } from 'reducks/reducers'
import { NotCostCalculatedReasons } from 'reducks/business/slice'

interface BreadState {
  bread: Bread | null
  breads: Bread[]
}

interface Bread {
  id: string
  allergens: string[]
  category: DocumentReference | null
  created_at: Timestamp | null
  description: string
  image_url: string
  image_urls: string[]
  is_set_allergens: boolean
  name: string
  price: number
  shop_ref: DocumentReference | null
  short_name: string
  updated_at: Timestamp | null
  // 下記ダセルーノのみで使用する項目
  author: string
  price_card_name: string | null
  price_card_description: string | null
  representation_law_name: string
  representation_law_name_other: string | null
  selling_price: number
  eat_in_price: number
  consumption_period: boolean //未使用
  appreciation_period: boolean //未使用
  period_date: number
  period_date_after_thawing: number | null
  preservation_method: string
  is_consumption_period: boolean
  is_consumption_period_after_thawing: boolean
  is_salling: boolean
  internal_capacity_count: number | null
  internal_capacity_unit: string | null
  packaging_cost: number
  memo: string
  contamination: string
  daseruno_status: BreadStatus
  daseruno_recipe_status: BreadStatus
  backing_sticker_status: BackingStickerStatus
  // 原価表示に使用
  cost_price: number | null
  pre_change_cost_price: number | null
  ideal_cost_rate: number | null //未使用
  not_cost_calculated_reasons: NotCostCalculatedReasons
  not_cost_calculated_recipe_reasons: string[] | null
  is_cost_calculating: boolean
  ingredient_name_for_seal: string | null
}

export type BreadType = Bread

export enum Allergens {
  EGG = 'egg',
  MILK = 'milk',
  WHEAT = 'wheat',
  SHRIMP = 'shrimp',
  CRUB = 'crub',
  PEANUTS = 'peanuts',
  SOBA = 'soba',
  ALMOND = 'almond',
  ABALONE = 'abalone',
  SQUID = 'squid',
  IKURA = 'ikura',
  ORANGE = 'orange',
  CASHEWNUTS = 'cashewnuts',
  KIWIFRUIT = 'kiwifruit',
  BEEF = 'beef',
  WALNUT = 'walnut',
  SESAME = 'sesame',
  SALMON = 'salmon',
  MACKEREL = 'mackerel',
  SOY = 'soy',
  CHICKEN = 'chicken',
  BANANA = 'banana',
  PORK = 'pork',
  MACADAMIANUTS = 'macadamianuts',
  PEACH = 'peach',
  YAM = 'yam',
  APPLE = 'apple',
  GELATINE = 'gelatine',
}

export enum BreadStatus {
  PUBLIC = 'public',
  DRAFT = 'draft',
  // 以下はbreadsコレクションのstatusにのみ使用されている(sacri側で使用)
  DELETE = 'delete',
  PRIVATE = 'private',
}

export enum BreadRepresentationLawName {
  RECTANGULAR_BREAD = 'rectangular_bread',
  SWEETENED_BUN = 'sweetened_bun',
  BREAD = 'bread',
  BAKED_SWEETS = 'baked_sweets',
  WESTERN_SWEETS = 'western_sweets',
  FRESH_WESTERN_SWEETS = 'fresh_western_sweets',
  OTHER = 'other',
}

export enum BreadRepresentationLawNameLabel {
  RECTANGULAR_BREAD = '食パン',
  SWEETENED_BUN = '菓子パン',
  BREAD = 'パン',
  BAKED_SWEETS = '焼き菓子',
  WESTERN_SWEETS = '洋菓子',
  FRESH_WESTERN_SWEETS = '洋生菓子',
  OTHER = 'その他',
}

export enum BackingStickerStatus {
  OK = 'ok',
  NG = 'ng',
  ANALYSIS = 'analysis',
}

const initialState = {
  bread: null,
  breads: [],
} as BreadState

export const getBreads = createAsyncThunk<
  Bread[],
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/getBreads', async (_, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return []
  }
  const q = query(
    collection(db, 'breads'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    where('status', 'in', [BreadStatus.PUBLIC, BreadStatus.PRIVATE]),
    orderBy('name'),
  )
  const querySnapshot = await getDocs(q)
  const breads: Bread[] = []
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const bread = doc.data()
      breads.push({
        id: doc.id,
        allergens: bread.allergens,
        author: bread.author,
        category: bread.category,
        created_at: bread.created_at,
        description: bread.description,
        image_url: bread.image_url,
        image_urls: bread.image_urls,
        is_set_allergens: bread.is_set_allergens,
        name: bread.name,
        price: bread.price,
        shop_ref: bread.shop_ref,
        short_name: bread.short_name,
        updated_at: bread.updated_at,
        price_card_name: bread.price_card_name,
        price_card_description: bread.price_card_description,
        representation_law_name: bread.representation_law_name,
        representation_law_name_other: bread.representation_law_name_other,
        selling_price: bread.selling_price,
        eat_in_price: bread.eat_in_price,
        consumption_period: bread.consumption_period,
        appreciation_period: bread.appreciation_period,
        period_date: bread.period_date,
        period_date_after_thawing: bread.period_date_after_thawing,
        preservation_method: bread.preservation_method,
        is_consumption_period: bread.is_consumption_period,
        is_consumption_period_after_thawing:
          bread.is_consumption_period_after_thawing,
        is_salling: bread.is_salling,
        internal_capacity_count: bread.internal_capacity_count,
        internal_capacity_unit: bread.internal_capacity_unit,
        packaging_cost: bread.packaging_cost,
        memo: bread.memo,
        contamination: bread.contamination,
        daseruno_status: bread.daseruno_status,
        daseruno_recipe_status: bread.daseruno_recipe_status,
        backing_sticker_status: bread.backing_sticker_status,
        cost_price: bread.cost_price,
        pre_change_cost_price: bread.pre_change_cost_price,
        ideal_cost_rate: bread.ideal_cost_rate,
        not_cost_calculated_reasons: bread.not_cost_calculated_reasons,
        not_cost_calculated_recipe_reasons:
          bread.not_cost_calculated_recipe_reasons,
        is_cost_calculating: bread.is_cost_calculating,
        ingredient_name_for_seal: bread.ingredient_name_for_seal,
      })
    }),
  )
  return breads
})

export const getBreadById = createAsyncThunk<
  Bread | null,
  { id: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/getBreadById', async ({ id }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff) {
    return null
  }
  const breadQuery = doc(db, 'breads', id)
  const breadQuerySnapshot = await getDoc(breadQuery)
  const bread = breadQuerySnapshot.data()
  if (!bread) {
    return null
  }
  const breadShopId = bread.shop_ref.id
    ? bread.shop_ref.id
    : // @ts-ignore
      bread.shop_ref._key.path.segments[6]
  if (!staff.isAdmin && breadShopId !== staff.shopId) {
    return null
  }
  const breadResponse: Bread = {
    id: breadQuerySnapshot.id,
    allergens: bread.allergens,
    author: bread.author,
    category: bread.category,
    created_at: bread.created_at,
    description: bread.description,
    image_url: bread.image_url,
    image_urls: bread.image_urls,
    is_set_allergens: bread.is_set_allergens,
    name: bread.name,
    price: bread.price,
    shop_ref: bread.shop_ref,
    short_name: bread.short_name,
    updated_at: bread.updated_at,
    price_card_name: bread.price_card_name,
    price_card_description: bread.price_card_description,
    representation_law_name: bread.representation_law_name,
    representation_law_name_other: bread.representation_law_name_other,
    selling_price: bread.selling_price,
    eat_in_price: bread.eat_in_price,
    consumption_period: bread.consumption_period,
    appreciation_period: bread.appreciation_period,
    period_date: bread.period_date,
    period_date_after_thawing: bread.period_date_after_thawing,
    preservation_method: bread.preservation_method,
    is_consumption_period: bread.is_consumption_period,
    is_consumption_period_after_thawing:
      bread.is_consumption_period_after_thawing,
    is_salling: bread.is_salling,
    internal_capacity_count: bread.internal_capacity_count,
    internal_capacity_unit: bread.internal_capacity_unit,
    packaging_cost: bread.packaging_cost,
    memo: bread.memo,
    contamination: bread.contamination,
    daseruno_status: bread.daseruno_status,
    daseruno_recipe_status: bread.daseruno_recipe_status,
    backing_sticker_status: bread.backing_sticker_status,
    cost_price: bread.cost_price,
    pre_change_cost_price: bread.pre_change_cost_price,
    ideal_cost_rate: bread.ideal_cost_rate,
    not_cost_calculated_reasons: bread.not_cost_calculated_reasons,
    not_cost_calculated_recipe_reasons:
      bread.not_cost_calculated_recipe_reasons,
    is_cost_calculating: bread.is_cost_calculating,
    ingredient_name_for_seal: bread.ingredient_name_for_seal,
  }
  return breadResponse
})

export const getBreadsByIds = createAsyncThunk<
  Bread[],
  { ids: string[] },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/getBreadsByIds', async ({ ids }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return []
  }
  const refList: DocumentReference[] = []
  await Promise.all(
    ids.map(async (id) => {
      refList.push(doc(db, 'breads', id))
    }),
  )
  const q = query(
    collection(db, 'breads'),
    where('shop_ref', '==', doc(db, 'shops', staff.shopId)),
    where(documentId(), 'in', refList),
  )
  const querySnapshot = await getDocs(q)
  const breads: Bread[] = []
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const bread = doc.data()
      breads.push({
        id: doc.id,
        allergens: bread.allergens,
        author: bread.author,
        category: bread.category,
        created_at: bread.created_at,
        description: bread.description,
        image_url: bread.image_url,
        image_urls: bread.image_urls,
        is_set_allergens: bread.is_set_allergens,
        name: bread.name,
        price: bread.price,
        shop_ref: bread.shop_ref,
        short_name: bread.short_name,
        updated_at: bread.updated_at,
        price_card_name: bread.price_card_name,
        price_card_description: bread.price_card_description,
        representation_law_name: bread.representation_law_name,
        representation_law_name_other: bread.representation_law_name_other,
        selling_price: bread.selling_price,
        eat_in_price: bread.eat_in_price,
        consumption_period: bread.consumption_period,
        appreciation_period: bread.appreciation_period,
        period_date: bread.period_date,
        period_date_after_thawing: bread.period_date_after_thawing,
        preservation_method: bread.preservation_method,
        is_consumption_period: bread.is_consumption_period,
        is_consumption_period_after_thawing:
          bread.is_consumption_period_after_thawing,
        is_salling: bread.is_salling,
        internal_capacity_count: bread.internal_capacity_count,
        internal_capacity_unit: bread.internal_capacity_unit,
        packaging_cost: bread.packaging_cost,
        memo: bread.memo,
        contamination: bread.contamination,
        daseruno_status: bread.daseruno_status,
        daseruno_recipe_status: bread.daseruno_recipe_status,
        backing_sticker_status: bread.backing_sticker_status,
        cost_price: bread.cost_price,
        pre_change_cost_price: bread.pre_change_cost_price,
        ideal_cost_rate: bread.ideal_cost_rate,
        not_cost_calculated_reasons: bread.not_cost_calculated_reasons,
        not_cost_calculated_recipe_reasons:
          bread.not_cost_calculated_recipe_reasons,
        is_cost_calculating: bread.is_cost_calculating,
        ingredient_name_for_seal: bread.ingredient_name_for_seal,
      })
    }),
  )
  return breads
})

export const getBreadByRecipeId = createAsyncThunk<
  Bread | null,
  { id: string },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/getBreadByRecipeId', async ({ id }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff || !staff.shopId) {
    return null
  }
  const recipeQuery = doc(db, 'recipes', id)
  const recipeQuerySnapshot = await getDoc(recipeQuery)
  const recipe = recipeQuerySnapshot.data()
  if (!recipe || !recipe.bread_ref) {
    return null
  }
  const breadQuery = doc(db, 'breads', recipe.bread_ref.id)
  const breadQuerySnapshot = await getDoc(breadQuery)
  const bread = breadQuerySnapshot.data()
  if (!bread) {
    return null
  }
  const breadShopId = bread.shop_ref.id
    ? bread.shop_ref.id
    : // @ts-ignore
      bread.shop_ref._key.path.segments[6]
  if (breadShopId !== staff.shopId) {
    return null
  }
  const breadResponse: Bread = {
    id: breadQuerySnapshot.id,
    allergens: bread.allergens,
    author: bread.author,
    category: bread.category,
    created_at: bread.created_at,
    description: bread.description,
    image_url: bread.image_url,
    image_urls: bread.image_urls,
    is_set_allergens: bread.is_set_allergens,
    name: bread.name,
    price: bread.price,
    shop_ref: bread.shop_ref,
    short_name: bread.short_name,
    updated_at: bread.updated_at,
    price_card_name: bread.price_card_name,
    price_card_description: bread.price_card_description,
    representation_law_name: bread.representation_law_name,
    representation_law_name_other: bread.representation_law_name_other,
    selling_price: bread.selling_price,
    eat_in_price: bread.eat_in_price,
    consumption_period: bread.consumption_period,
    appreciation_period: bread.appreciation_period,
    period_date: bread.period_date,
    period_date_after_thawing: bread.period_date_after_thawing,
    preservation_method: bread.preservation_method,
    is_consumption_period: bread.is_consumption_period,
    is_consumption_period_after_thawing:
      bread.is_consumption_period_after_thawing,
    is_salling: bread.is_salling,
    internal_capacity_count: bread.internal_capacity_count,
    internal_capacity_unit: bread.internal_capacity_unit,
    packaging_cost: bread.packaging_cost,
    memo: bread.memo,
    contamination: bread.contamination,
    daseruno_status: bread.daseruno_status,
    daseruno_recipe_status: bread.daseruno_recipe_status,
    backing_sticker_status: bread.backing_sticker_status,
    cost_price: bread.cost_price,
    pre_change_cost_price: bread.pre_change_cost_price,
    ideal_cost_rate: bread.ideal_cost_rate,
    not_cost_calculated_reasons: bread.not_cost_calculated_reasons,
    not_cost_calculated_recipe_reasons:
      bread.not_cost_calculated_recipe_reasons,
    is_cost_calculating: bread.is_cost_calculating,
    ingredient_name_for_seal: bread.ingredient_name_for_seal,
  }
  return breadResponse
})

export const createBread = createAsyncThunk<
  string | null,
  {
    allergens: string[]
    name: string
    short_name: string
    author: string
    price_card_name: string | null
    price_card_description: string | null
    categoey_doc_id: string
    selling_price: number
    eat_in_price: number
    representation_law_name: string
    representation_law_name_other: string | null
    is_consumption_period: boolean
    period_date: number
    is_consumption_period_after_thawing: boolean
    period_date_after_thawing: number | null
    preservation_method: string
    is_salling: boolean
    internal_capacity_count: number
    internal_capacity_unit: string
    packaging_cost: number | null
    memo: string
    contamination: string
    is_draft: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'bread/createBread',
  async (
    {
      allergens,
      name,
      short_name,
      author,
      price_card_name,
      price_card_description,
      categoey_doc_id,
      selling_price,
      eat_in_price,
      representation_law_name,
      representation_law_name_other,
      is_consumption_period,
      period_date,
      is_consumption_period_after_thawing,
      period_date_after_thawing,
      preservation_method,
      is_salling,
      internal_capacity_count,
      internal_capacity_unit,
      packaging_cost,
      memo,
      contamination,
      is_draft,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId || !staff.storeId) {
      return null
    }
    const breadRef = collection(db, 'breads')
    const is_set_allergens = allergens.length > 0 ? true : false
    const data = {
      allergens: allergens,
      author: author,
      created_at: serverTimestamp(),
      is_set_allergens: is_set_allergens,
      name: name,
      shop_ref: doc(db, 'shops', staff.shopId),
      short_name: short_name,
      updated_at: serverTimestamp(),
      price_card_name: price_card_name,
      price_card_description: price_card_description,
      category: categoey_doc_id ? doc(db, 'categories', categoey_doc_id) : null,
      price: selling_price, // 新規登録時のみsacriで使用する料金にもデフォルト値として登録する
      selling_price: selling_price,
      eat_in_price: eat_in_price,
      representation_law_name: representation_law_name,
      representation_law_name_other: representation_law_name_other
        ? representation_law_name_other
        : null,
      is_consumption_period: is_consumption_period,
      period_date: period_date,
      is_consumption_period_after_thawing: is_consumption_period_after_thawing,
      period_date_after_thawing: period_date_after_thawing,
      preservation_method: preservation_method,
      is_salling: is_salling,
      internal_capacity_count: internal_capacity_count,
      internal_capacity_unit: internal_capacity_unit,
      packaging_cost: packaging_cost,
      memo: memo,
      contamination: contamination,
      daseruno_status: is_draft ? BreadStatus.DRAFT : BreadStatus.PUBLIC,
      backing_sticker_status: BackingStickerStatus.NG,
      // sacriに必要なパラメータをデフォルトセット
      description: price_card_description ? price_card_description : '',
      is_type_daily: true,
      is_type_delivery: false,
      is_type_preorder: false,
      is_type_shared: false,
      order: 1,
      status: 'private',
    }
    const docRef = await addDoc(breadRef, data)
    // 参照データを追加
    const updateData = {
      bread_ref: doc(db, 'breads', docRef.id),
    }
    const updateBreadRef = doc(db, 'breads', docRef.id)
    await updateDoc(updateBreadRef, updateData)
    // shopsに参照データを追加
    const updateStoreBreadsData = {
      bread_ref: doc(db, 'breads', docRef.id),
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    }
    const updateStoreRef = collection(
      db,
      'stores',
      staff.storeId,
      'store_breads',
    )
    await addDoc(updateStoreRef, updateStoreBreadsData)
    return docRef.id
  },
)

export const updateBread = createAsyncThunk<
  string | null,
  {
    id: string
    allergens: string[]
    name: string
    short_name: string
    author: string
    price_card_name: string | null
    price_card_description: string | null
    categoey_doc_id: string
    selling_price: number
    eat_in_price: number
    representation_law_name: string
    representation_law_name_other: string | null
    is_consumption_period: boolean
    period_date: number
    is_consumption_period_after_thawing: boolean
    period_date_after_thawing: number | null
    preservation_method: string
    is_salling: boolean
    internal_capacity_count: number
    internal_capacity_unit: string
    packaging_cost: number | null
    memo: string
    contamination: string
    is_draft: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'bread/updateBread',
  async (
    {
      id,
      allergens,
      name,
      short_name,
      author,
      price_card_name,
      price_card_description,
      categoey_doc_id,
      selling_price,
      eat_in_price,
      representation_law_name,
      representation_law_name_other,
      is_consumption_period,
      period_date,
      is_consumption_period_after_thawing,
      period_date_after_thawing,
      preservation_method,
      is_salling,
      internal_capacity_count,
      internal_capacity_unit,
      packaging_cost,
      memo,
      contamination,
      is_draft,
    },
    thunkApi,
  ) => {
    const { staff } = thunkApi.getState().staffSlice
    if (!staff || !staff.shopId) {
      return null
    }
    const breadRef = doc(db, 'breads', id)
    const breadQuerySnapshot = await getDoc(breadRef)
    const bread = breadQuerySnapshot.data()
    if (!bread) {
      return null
    }
    const is_set_allergens = allergens.length > 0 ? true : false
    const data = {
      allergens: allergens,
      author: author,
      is_set_allergens: is_set_allergens,
      name: name,
      shop_ref: doc(db, 'shops', staff.shopId),
      short_name: short_name,
      updated_at: serverTimestamp(),
      price_card_name: price_card_name,
      price_card_description: price_card_description,
      category: categoey_doc_id ? doc(db, 'categories', categoey_doc_id) : null,
      selling_price: selling_price,
      eat_in_price: eat_in_price,
      representation_law_name: representation_law_name,
      representation_law_name_other: representation_law_name_other
        ? representation_law_name_other
        : null,
      is_consumption_period: is_consumption_period,
      period_date: period_date,
      is_consumption_period_after_thawing: is_consumption_period_after_thawing,
      period_date_after_thawing: period_date_after_thawing,
      preservation_method: preservation_method,
      is_salling: is_salling,
      internal_capacity_count: internal_capacity_count,
      internal_capacity_unit: internal_capacity_unit,
      packaging_cost: packaging_cost,
      memo: memo,
      contamination: contamination,
      pre_change_cost_price:
        bread.cost_price || bread.cost_price === 0 ? bread.cost_price : null,
      daseruno_status: is_draft ? BreadStatus.DRAFT : BreadStatus.PUBLIC,
    }
    await updateDoc(breadRef, data)
    return id
  },
)

export const uploadBreadImages = createAsyncThunk<
  string[],
  {
    breadId: string
    images: File[]
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/uploadBreadImages', async ({ breadId, images }, thunkApi) => {
  const { staff } = thunkApi.getState().staffSlice
  if (!staff) {
    return []
  }
  const breadQuery = doc(db, 'breads', breadId)
  const breadQuerySnapshot = await getDoc(breadQuery)
  const bread = breadQuerySnapshot.data()
  if (!bread) {
    return []
  }
  const breadShopId = bread.shop_ref.id
    ? bread.shop_ref.id
    : // @ts-ignore
      bread.shop_ref._key.path.segments[6]
  if (!staff.isAdmin && breadShopId !== staff.shopId) {
    return []
  }
  // 既存の商品画像があれば削除する
  // HACK: 変更差分だけ削除できるようになると理想
  if (bread.image_urls) {
    await Promise.all(
      bread.image_urls.map(async (image_url: string) => {
        const decodeUrl = decodeURIComponent(image_url)
        const refFileNameMatch = decodeUrl.match('.+/(.+?)([\\?#;].*)?$')
        if (!refFileNameMatch) {
          return
        }
        const refFileName = refFileNameMatch[1]
        const storageRef = ref(
          storage,
          `breads/${staff.storeId}/${breadId}/${refFileName}`,
        )
        await deleteObject(storageRef)
      }),
    )
  }
  // 新たな商品画像をアップロードする
  const downloadUrls: string[] = []
  await Promise.all(
    images.map(async (image, index) => {
      const fileName =
        format(new Date(), "yyyy-MM-dd'T'HH:mm:ss") + '_' + String(index)
      const fileExtension = image.type.split('/')[1]
      const storageRef = ref(
        storage,
        `breads/${staff.storeId}/${breadId}/${fileName}.${fileExtension}`,
      )
      await uploadBytesResumable(storageRef, image)
      const downloadUrl = await getDownloadURL(storageRef)
      downloadUrls.push(downloadUrl)
    }),
  )
  return downloadUrls
})

export const updateImageUrl = createAsyncThunk<
  void,
  {
    breadId: string
    image_urls: string[]
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/updateImageUrl', async ({ breadId, image_urls }) => {
  const docRef = doc(db, 'breads', breadId)
  await updateDoc(docRef, {
    image_url: image_urls[0],
    image_urls: image_urls,
  })
})

export const updateBackingStickerStatus = createAsyncThunk<
  void,
  {
    breadId: string
    backingStickerStatus: BackingStickerStatus
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/updateStatus', async ({ breadId, backingStickerStatus }) => {
  const docRef = doc(db, 'breads', breadId)
  await updateDoc(docRef, {
    backing_sticker_status: backingStickerStatus,
  })
})

export const updateStatus = createAsyncThunk<
  void,
  {
    breadId: string
    status: BreadStatus
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/updateStatus', async ({ breadId, status }) => {
  const docRef = doc(db, 'breads', breadId)
  await updateDoc(docRef, {
    status: status,
  })
})

export const updateBreadDaserunoRecipeStatus = createAsyncThunk<boolean>(
  'bread/updateBreadDaserunoRecipeStatus',
  async () => {
    const q = query(collection(db, 'recipes'))
    const querySnapshot = await getDocs(q)
    await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const recipe = doc.data()
        const breadData = {
          daseruno_recipe_status: recipe.is_draft
            ? BreadStatus.DRAFT
            : BreadStatus.PUBLIC,
        }
        await updateDoc(recipe.bread_ref, breadData)
      }),
    )
    return true
  },
)

export const updateCost = createAsyncThunk<
  void,
  {
    id: string
    cost_price: number | null
    not_cost_calculated_reasons: NotCostCalculatedReasons
    not_cost_calculated_recipe_reasons: string[] | null
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'bread/updateCost',
  async ({
    id,
    cost_price,
    not_cost_calculated_reasons,
    not_cost_calculated_recipe_reasons,
  }) => {
    const docRef = doc(db, 'breads', id)
    await updateDoc(docRef, {
      cost_price: cost_price,
      not_cost_calculated_reasons: not_cost_calculated_reasons,
      not_cost_calculated_recipe_reasons: not_cost_calculated_recipe_reasons,
    })
  },
)

export const updatePrechangeCostPrice = createAsyncThunk<
  void,
  {
    id: string
    pre_change_cost_price: number | null
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/updatePrechangeCostPrice', async ({ id, pre_change_cost_price }) => {
  const docRef = doc(db, 'breads', id)
  await updateDoc(docRef, {
    pre_change_cost_price: pre_change_cost_price,
  })
})

export const updateIngredientNameForSeal = createAsyncThunk<
  void,
  {
    id: string
    ingredient_name_for_seal: string | null
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>(
  'bread/updateIngredientNameForSeal',
  async ({ id, ingredient_name_for_seal }) => {
    const docRef = doc(db, 'breads', id)
    await updateDoc(docRef, {
      ingredient_name_for_seal: ingredient_name_for_seal,
    })
  },
)

export const getAllDaserunoBread = createAsyncThunk<
  Bread[],
  void,
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/getAllDaserunoBread', async (_) => {
  const q = query(
    collection(db, 'breads'),
    where('daseruno_status', 'in', [BreadStatus.PUBLIC, BreadStatus.DRAFT]),
  )
  const querySnapshot = await getDocs(q)
  const breads: Bread[] = []
  await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      const bread = doc.data()
      breads.push({
        id: doc.id,
        allergens: bread.allergens,
        author: bread.author,
        category: bread.category,
        created_at: bread.created_at,
        description: bread.description,
        image_url: bread.image_url,
        image_urls: bread.image_urls,
        is_set_allergens: bread.is_set_allergens,
        name: bread.name,
        price: bread.price,
        shop_ref: bread.shop_ref,
        short_name: bread.short_name,
        updated_at: bread.updated_at,
        price_card_name: bread.price_card_name,
        price_card_description: bread.price_card_description,
        representation_law_name: bread.representation_law_name,
        representation_law_name_other: bread.representation_law_name_other,
        selling_price: bread.selling_price,
        eat_in_price: bread.eat_in_price,
        consumption_period: bread.consumption_period,
        appreciation_period: bread.appreciation_period,
        period_date: bread.period_date,
        period_date_after_thawing: bread.period_date_after_thawing,
        preservation_method: bread.preservation_method,
        is_consumption_period: bread.is_consumption_period,
        is_consumption_period_after_thawing:
          bread.is_consumption_period_after_thawing,
        is_salling: bread.is_salling,
        internal_capacity_count: bread.internal_capacity_count,
        internal_capacity_unit: bread.internal_capacity_unit,
        packaging_cost: bread.packaging_cost,
        memo: bread.memo,
        contamination: bread.contamination,
        daseruno_status: bread.daseruno_status,
        daseruno_recipe_status: bread.daseruno_recipe_status,
        backing_sticker_status: bread.backing_sticker_status,
        cost_price: bread.cost_price,
        pre_change_cost_price: bread.pre_change_cost_price,
        ideal_cost_rate: bread.ideal_cost_rate,
        not_cost_calculated_reasons: bread.not_cost_calculated_reasons,
        not_cost_calculated_recipe_reasons:
          bread.not_cost_calculated_recipe_reasons,
        is_cost_calculating: bread.is_cost_calculating,
        ingredient_name_for_seal: bread.ingredient_name_for_seal,
      })
    }),
  )
  return breads
})

export const updateSellingPrice = createAsyncThunk<
  void,
  {
    id: string
    selling_price: number | null
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/updateIdealCostRate', async ({ id, selling_price }) => {
  const docRef = doc(db, 'breads', id)
  await updateDoc(docRef, {
    selling_price: selling_price,
  })
})

export const updateIdealCostRate = createAsyncThunk<
  void,
  {
    id: string
    ideal_cost_rate: number | null
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/updateIdealCostRate', async ({ id, ideal_cost_rate }) => {
  const docRef = doc(db, 'breads', id)
  await updateDoc(docRef, {
    ideal_cost_rate: ideal_cost_rate,
  })
})

export const updateIsCostCalculating = createAsyncThunk<
  void,
  {
    id: string
    is_cost_calculating: boolean
  },
  {
    dispatch: AppDispatch
    state: RootState
  }
>('bread/updateIsCostCalculating', async ({ id, is_cost_calculating }) => {
  const docRef = doc(db, 'breads', id)
  await updateDoc(docRef, {
    is_cost_calculating: is_cost_calculating,
  })
})

export const breadSlice: Slice<
  BreadState,
  { clearBread(state: BreadState): void; clearBreads(state: BreadState): void },
  'bread'
> = createSlice({
  name: 'bread',
  initialState,
  reducers: {
    clearBread(state: BreadState) {
      state.bread = null
    },
    clearBreads(state: BreadState) {
      state.breads = []
    },
  },
  extraReducers: {
    [getBreads.fulfilled.type]: (
      state,
      action: PayloadAction<Bread[] | null>,
    ) => {
      if (action.payload !== null) {
        state.breads = action.payload
      }
    },
  },
})

export const { clearBread, clearBreads } = breadSlice.actions
export default breadSlice.reducer
