import { FC, useState, useEffect, useCallback } from 'react'
import styles from 'styles/components/form.module.scss'
import SubmitButton from 'components/Button/SubmitButton'
import InputTextWithLabel from 'components/Form/WithLabel/InputText'
import SelectBoxWithLabel from 'components/Form/WithLabel/SelectBox'
import RadioButtonWithLabel from 'components/Form/WithLabel/RadioButton'
import RadioButton from 'components/Form/RadioButton'
import InputTextAndUnitWithLabel from 'components/Form/WithLabel/InputTextAndUnit'
import InputTextSuggestWithLabel from 'components/Form/WithLabel/InputTextSuggest'
import { suggestInfo } from 'components/Form/WithLabel/InputTextSuggest'
import TextAreaWithLabel from 'components/Form/WithLabel/TextArea'
import SelectBox from 'components/Form/SelectBox'
import InputText from 'components/Form/InputText'
import Big from 'big.js'

type Props = {
  formInfos: AddFormSupplierInfo[]
  handleSupplierInfos: (item: AddFormSupplierInfo[]) => void
  handleInternalCapacityError: (errorFlg: boolean) => void
  handleSupplierCategoryError: (errorFlg: boolean) => void
  handleSupplierCompanyNameError: (errorFlg: boolean) => void
  handleSupplierBranchNameError: (errorFlg: boolean) => void
  handleCostPriceError: (errorFlg: boolean) => void
  handleTaxError: (errorFlg: boolean) => void
  handleInternalCapacityCountError: (errorFlg: boolean) => void
  processValidate: boolean
  handleProcessSupplierInfoValidate: (
    processSupplierInfoValidate: boolean,
  ) => void
  yieldRate: number
}

export type AddFormSupplierInfo = {
  internalCapacity: AddFormInternalCapacityInfo | null
  internalCapacityManualInput: string | null
  internalCapacityCount: string
  internalCapacityUnit: string
  numberItem: string
  numberItemUnit: string
  supplierCategory: string
  supplierCompanyName: string
  supplierBranchName: string
  costPrice: string
  taxIncludedCostPrice: string
  tax: number
  memo: string
  usedCosting: boolean
  unitAdjustment: number
}

export type AddFormInternalCapacityInfo = {
  internalCapacityCount: string
  internalCapacityUnit: string
  numberItem: string | null
  numberItemUnit: string | null
  jancode: string | null
  originName: string | null
}

const AddFormSupplierInformation: FC<Props> = (props) => {
  const {
    formInfos,
    handleSupplierInfos,
    // handleInternalCapacityError, // TODO: Propsや呼び出し元の処理の削除を行う
    handleSupplierCategoryError,
    handleSupplierCompanyNameError,
    handleSupplierBranchNameError,
    handleCostPriceError,
    handleTaxError,
    handleInternalCapacityCountError,
    processValidate,
    handleProcessSupplierInfoValidate,
    yieldRate,
  } = props

  const [formItems, setFormItems] = useState<AddFormSupplierInfo[]>([])
  // const [internalCapacityErrorTexts, setInternalCapacityErrorTexts] = useState<
  //   string[]
  // >([''])
  const [supplierCategoryErrorTexts, setSupplierCategoryErrorTexts] = useState<
    string[]
  >([''])
  const [supplierCompanyNameErrorTexts, setSupplierCompanyNameErrorTexts] =
    useState<string[]>([''])
  const [supplierBranchNameErrorTexts, setSupplierBranchNameErrorTexts] =
    useState<string[]>([''])
  const [costPriceErrorTexts, setCostPriceErrorTexts] = useState<string[]>([''])
  const [taxErrorTexts, setTaxErrorTexts] = useState<string[]>([''])
  const [internalCapacityCountErrorTexts, setInternalCapacityCountErrorTexts] =
    useState<string[]>([''])

  const handleSetFormItems = (newItems: AddFormSupplierInfo[]) => {
    setFormItems(newItems)
    handleSupplierInfos(newItems)
  }

  const handleAddButton = () => {
    const newItem = {
      internalCapacity: null,
      internalCapacityManualInput: null,
      internalCapacityCount: '',
      internalCapacityUnit: '',
      numberItem: '',
      numberItemUnit: '',
      supplierCategory: '',
      supplierCompanyName: '',
      supplierBranchName: '',
      costPrice: '',
      taxIncludedCostPrice: '',
      tax: 8,
      memo: '',
      usedCosting: false,
      unitAdjustment: 1,
    }
    const newItems = [...formItems, newItem]
    const newSupplierCategoryErrorTexts = [...supplierCategoryErrorTexts, '']
    const newSupplierCompanyNameErrorTexts = [
      ...supplierCompanyNameErrorTexts,
      '',
    ]
    const newSupplierBranchNameErrorTexts = [
      ...supplierBranchNameErrorTexts,
      '',
    ]
    const newCostPriceErrorTexts = [...costPriceErrorTexts, '']
    const newTaxErrorTexts = [...taxErrorTexts, '']
    const newInternalCapacityCountErrorTexts = [
      ...internalCapacityCountErrorTexts,
      '',
    ]

    setSupplierCategoryErrorTexts(newSupplierCategoryErrorTexts)
    setSupplierCompanyNameErrorTexts(newSupplierCompanyNameErrorTexts)
    setSupplierBranchNameErrorTexts(newSupplierBranchNameErrorTexts)
    setCostPriceErrorTexts(newCostPriceErrorTexts)
    setTaxErrorTexts(newTaxErrorTexts)
    setInternalCapacityCountErrorTexts(newInternalCapacityCountErrorTexts)
    handleSetFormItems(newItems)
  }

  const handleDeleteButton = (index: number) => {
    const newItems = [...formItems]
    const newSupplierCategoryErrorTexts = [...supplierCategoryErrorTexts]
    const newSupplierCompanyNameErrorTexts = [...supplierCompanyNameErrorTexts]
    const newSupplierBranchNameErrorTexts = [...supplierBranchNameErrorTexts]
    const newCostPriceErrorTexts = [...costPriceErrorTexts]
    const newTaxErrorTexts = [...taxErrorTexts]
    const newInternalCapacityCountErrorTexts = [
      ...internalCapacityCountErrorTexts,
    ]
    // 情報は1つは残す
    if (newItems.length > 1) {
      // 削除する仕入れ先情報のusedCostingがtrueだった場合は戦闘データをtrueにする
      if (newItems[index].usedCosting) {
        newItems[0].usedCosting = true
      }
      newItems.splice(index, 1)
      newSupplierCategoryErrorTexts.splice(index, 1)
      newSupplierCompanyNameErrorTexts.splice(index, 1)
      newSupplierBranchNameErrorTexts.splice(index, 1)
      newCostPriceErrorTexts.splice(index, 1)
      newTaxErrorTexts.splice(index, 1)
      newInternalCapacityCountErrorTexts.splice(index, 1)
      setSupplierCategoryErrorTexts(newSupplierCategoryErrorTexts)
      setSupplierCompanyNameErrorTexts(newSupplierCompanyNameErrorTexts)
      setSupplierBranchNameErrorTexts(newSupplierBranchNameErrorTexts)
      setCostPriceErrorTexts(newCostPriceErrorTexts)
      setTaxErrorTexts(newTaxErrorTexts)
      setInternalCapacityCountErrorTexts(newInternalCapacityCountErrorTexts)
      handleSetFormItems(newItems)
    }
  }

  const handleInternalCapacityCount = (
    index: number,
    internalCapacityCount: string,
  ) => {
    // 半角数字に変換
    internalCapacityCount = internalCapacityCount.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(internalCapacityCount) && Number(internalCapacityCount) !== 0) {
      return
    }
    const newItems = [...formItems]
    newItems[index].internalCapacityCount = internalCapacityCount
    handleSetFormItems(newItems)
  }

  const handleInternalCapacityUnit = (
    index: number,
    internalCapacityUnit: string,
  ) => {
    const newItems = [...formItems]
    newItems[index].internalCapacityUnit = internalCapacityUnit
    if (internalCapacityUnit === 'kg' || internalCapacityUnit === 'L') {
      newItems[index].unitAdjustment = 1000
    } else {
      newItems[index].unitAdjustment = 1
    }
    handleSetFormItems(newItems)
  }

  const handleNumberItem = (index: number, numberItem: string) => {
    // 半角数字に変換
    numberItem = numberItem.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(numberItem) && Number(numberItem) !== 0) {
      return
    }
    const newItems = [...formItems]
    newItems[index].numberItem = numberItem
    handleSetFormItems(newItems)
  }

  const handleNumberItemUnit = (index: number, numberItemUnit: string) => {
    const newItems = [...formItems]
    newItems[index].numberItemUnit = numberItemUnit
    handleSetFormItems(newItems)
  }

  const handleSupplierCategory = (index: number, supplierCategory: string) => {
    const newItems = [...formItems]
    newItems[index].supplierCategory = supplierCategory
    handleSetFormItems(newItems)
    handleValidateSupplierCategory(
      index,
      supplierCategory,
      supplierCategoryErrorTexts,
    )
  }

  const handleSupplierCompanyName = (
    index: number,
    supplierCompanyName: string,
  ) => {
    const newItems = [...formItems]
    newItems[index].supplierCompanyName = supplierCompanyName
    handleSetFormItems(newItems)
  }

  const handleSupplierBranchName = (
    index: number,
    supplierBranchName: string,
  ) => {
    const newItems = [...formItems]
    newItems[index].supplierBranchName = supplierBranchName
    handleSetFormItems(newItems)
  }

  const handleCostPrice = (index: number, costPrice: string) => {
    // 半角数字に変換
    costPrice = costPrice.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(costPrice) && Number(costPrice) !== 0) {
      return
    }
    const newItems = [...formItems]
    newItems[index].costPrice = costPrice
    handleSetFormItems(newItems)
  }

  const handleTaxIncludedCostPrice = (
    index: number,
    taxIncludedCostPrice: string,
  ) => {
    // 半角数字に変換
    taxIncludedCostPrice = taxIncludedCostPrice.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(taxIncludedCostPrice) && Number(taxIncludedCostPrice) !== 0) {
      return
    }
    const newItems = [...formItems]
    newItems[index].taxIncludedCostPrice = taxIncludedCostPrice
    handleSetFormItems(newItems)
  }

  const handleTaxIncludedCostPriceToCostPrice = (
    index: number,
    taxIncludedCostPrice: string,
  ) => {
    // 半角数字に変換
    taxIncludedCostPrice = taxIncludedCostPrice.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
    if (!Number(taxIncludedCostPrice) && Number(taxIncludedCostPrice) !== 0) {
      return
    }
    const newItems = [...formItems]
    newItems[index].costPrice = String(
      Math.floor(
        new Big(Number(taxIncludedCostPrice))
          .div(1 + newItems[index].tax / 100)
          .toNumber() * 100000,
      ) / 100000,
    )
    handleSetFormItems(newItems)
  }

  const handleTax = (index: number, tax: number) => {
    const newItems = [...formItems]
    newItems[index].tax = tax
    handleSetFormItems(newItems)
    handleValidateTax(index, tax, taxErrorTexts)
  }

  const handleMemo = (index: number, memo: string) => {
    const newItems = [...formItems]
    newItems[index].memo = memo
    handleSetFormItems(newItems)
  }

  const handleUsedCosting = (index: number, usedCosting: boolean) => {
    // 仕入れ先情報が1件しかなければ操作ができない
    if (formItems.length <= 1) {
      return
    }
    // trueのものはfalseにできない
    if (formItems[index].usedCosting === true) {
      return
    }
    // 他の仕入れ先のusedCostingをfalseにする
    const newItems = formItems.map((formItem) => {
      formItem.usedCosting = false
      return formItem
    })
    newItems[index].usedCosting = usedCosting
    handleSetFormItems(newItems)
  }

  // const handleValidateInternalCapacity = (
  //   index: number,
  //   internalCapacity: string | AddFormInternalCapacityInfo | null,
  //   internalCapacityErrorTexts: string[],
  // ) => {
  //   const newInternalCapacityErrorTexts = [...internalCapacityErrorTexts]
  //   // 必須入力
  //   if (!internalCapacity) {
  //     newInternalCapacityErrorTexts[index] = '入力してください'
  //     setInternalCapacityErrorTexts(newInternalCapacityErrorTexts)
  //     handleInternalCapacityError(true)
  //     return newInternalCapacityErrorTexts
  //   } else {
  //     newInternalCapacityErrorTexts[index] = ''
  //   }
  //   setInternalCapacityErrorTexts(newInternalCapacityErrorTexts)
  //   let errorFlg = false
  //   newInternalCapacityErrorTexts.forEach((newInternalCapacityErrorText) => {
  //     if (newInternalCapacityErrorText) {
  //       errorFlg = true
  //     }
  //   })
  //   handleInternalCapacityError(errorFlg)
  //   return newInternalCapacityErrorTexts
  // }

  const handleValidateInternalCapacityCount = (
    index: number,
    internalCapacityCount: string,
  ) => {
    const newInternalCapacityCountErrorTexts = [
      ...internalCapacityCountErrorTexts,
    ]
    // 0は入力できない
    if (internalCapacityCount !== '' && Number(internalCapacityCount) === 0) {
      newInternalCapacityCountErrorTexts[index] = '内容量0は設定できません'
      setInternalCapacityCountErrorTexts(newInternalCapacityCountErrorTexts)
      handleInternalCapacityCountError(true)
      return
    } else {
      newInternalCapacityCountErrorTexts[index] = ''
    }
    setInternalCapacityCountErrorTexts(newInternalCapacityCountErrorTexts)
    let errorFlg = false
    newInternalCapacityCountErrorTexts.forEach(
      (newInternalCapacityCountErrorText) => {
        if (newInternalCapacityCountErrorText) {
          errorFlg = true
        }
      },
    )
    handleInternalCapacityCountError(errorFlg)
  }

  const handleValidateSupplierCategory = (
    index: number,
    supplierCategory: string,
    supplierCategoryErrorTexts: string[],
  ) => {
    const newSupplierCategoryErrorTexts = [...supplierCategoryErrorTexts]
    // 必須入力
    if (!supplierCategory) {
      newSupplierCategoryErrorTexts[index] = '入力してください'
      setSupplierCategoryErrorTexts(newSupplierCategoryErrorTexts)
      handleSupplierCategoryError(true)
      return newSupplierCategoryErrorTexts
    } else {
      newSupplierCategoryErrorTexts[index] = ''
    }
    setSupplierCategoryErrorTexts(newSupplierCategoryErrorTexts)
    let errorFlg = false
    newSupplierCategoryErrorTexts.forEach((newSupplierCategoryErrorText) => {
      if (newSupplierCategoryErrorText) {
        errorFlg = true
      }
    })
    handleSupplierCategoryError(errorFlg)
    return newSupplierCategoryErrorTexts
  }

  const handleValidateSupplierCompanyName = (
    index: number,
    supplierCompanyName: string,
    supplierCompanyNameErrorTexts: string[],
  ) => {
    const newSupplierCompanyNameErrorTexts = [...supplierCompanyNameErrorTexts]
    // 必須入力
    if (!supplierCompanyName) {
      newSupplierCompanyNameErrorTexts[index] = '入力してください'
      setSupplierCompanyNameErrorTexts(newSupplierCompanyNameErrorTexts)
      handleSupplierCompanyNameError(true)
      return newSupplierCompanyNameErrorTexts
    } else {
      newSupplierCompanyNameErrorTexts[index] = ''
    }
    // 30字以内
    if (supplierCompanyName.length > 30) {
      newSupplierCompanyNameErrorTexts[index] = '30文字以内で入力してください'
      setSupplierCompanyNameErrorTexts(newSupplierCompanyNameErrorTexts)
      handleSupplierCompanyNameError(true)
      return newSupplierCompanyNameErrorTexts
    } else {
      newSupplierCompanyNameErrorTexts[index] = ''
    }
    setSupplierCompanyNameErrorTexts(newSupplierCompanyNameErrorTexts)
    let errorFlg = false
    newSupplierCompanyNameErrorTexts.forEach(
      (newSupplierCompanyNameErrorText) => {
        if (newSupplierCompanyNameErrorText) {
          errorFlg = true
        }
      },
    )
    handleSupplierCompanyNameError(errorFlg)
    return newSupplierCompanyNameErrorTexts
  }

  const handleValidateSupplierBranchName = (
    index: number,
    supplierBranchName: string,
    supplierBranchNameErrorTexts: string[],
  ) => {
    const newSupplierBranchNameErrorTexts = [...supplierBranchNameErrorTexts]
    // 30字以内
    if (supplierBranchName.length > 30) {
      newSupplierBranchNameErrorTexts[index] = '30文字以内で入力してください'
      setSupplierBranchNameErrorTexts(newSupplierBranchNameErrorTexts)
      handleSupplierBranchNameError(true)
      return newSupplierBranchNameErrorTexts
    } else {
      newSupplierBranchNameErrorTexts[index] = ''
    }
    setSupplierBranchNameErrorTexts(newSupplierBranchNameErrorTexts)
    let errorFlg = false
    newSupplierBranchNameErrorTexts.forEach(
      (newSupplierBranchNameErrorText) => {
        if (newSupplierBranchNameErrorText) {
          errorFlg = true
        }
      },
    )
    handleSupplierBranchNameError(errorFlg)
    return newSupplierBranchNameErrorTexts
  }

  const handleValidateCostPrice = (
    index: number,
    costPrice: string,
    costPriceErrorTexts: string[],
  ) => {
    const newCostPriceErrorTexts = [...costPriceErrorTexts]
    // 必須入力
    if (!costPrice) {
      newCostPriceErrorTexts[index] = '入力してください'
      setCostPriceErrorTexts(newCostPriceErrorTexts)
      handleCostPriceError(true)
      return newCostPriceErrorTexts
    } else {
      newCostPriceErrorTexts[index] = ''
    }
    // 数値形式
    if (!Number(costPrice) && Number(costPrice) !== 0) {
      newCostPriceErrorTexts[index] = '数値形式で入力してください'
      setCostPriceErrorTexts(newCostPriceErrorTexts)
      handleCostPriceError(true)
      return newCostPriceErrorTexts
    } else {
      newCostPriceErrorTexts[index] = ''
    }
    setCostPriceErrorTexts(newCostPriceErrorTexts)
    let errorFlg = false
    newCostPriceErrorTexts.forEach((newCostPriceErrorText) => {
      if (newCostPriceErrorText) {
        errorFlg = true
      }
    })
    handleCostPriceError(errorFlg)
    return newCostPriceErrorTexts
  }

  const handleValidateTax = (
    index: number,
    tax: number,
    taxErrorTexts: string[],
  ) => {
    const newTaxErrorTexts = [...taxErrorTexts]
    // 必須入力
    if (!tax) {
      newTaxErrorTexts[index] = '入力してください'
      setTaxErrorTexts(newTaxErrorTexts)
      handleTaxError(true)
      return newTaxErrorTexts
    } else {
      newTaxErrorTexts[index] = ''
    }
    setTaxErrorTexts(newTaxErrorTexts)
    let errorFlg = false
    newTaxErrorTexts.forEach((newTaxErrorText) => {
      if (newTaxErrorText) {
        errorFlg = true
      }
    })
    handleTaxError(errorFlg)
    return newTaxErrorTexts
  }

  const handleSupplierCompanyNameSuggest = async (
    info: suggestInfo,
    index: number | null | undefined,
  ) => {
    console.log(index, info)
    if (index === null || index === undefined) {
      return
    }
    handleSupplierCompanyName(index, info.name)
  }

  useEffect(() => {
    // const newInternalCapacityInputs: string[] = []
    // formInfos.forEach((formInfo) => {
    //   if (formInfo.internalCapacity && formInfo.internalCapacity.internalCapacityCount && formInfo.internalCapacity.internalCapacityUnit) {
    //     newInternalCapacityInputs.push(String(formInfo.internalCapacity.internalCapacityCount) + formInfo.internalCapacity.internalCapacityUnit)
    //   } else {
    //     newInternalCapacityInputs.push("")
    //   }
    // })
    // setInternalCapacityInputs(newInternalCapacityInputs)
    setFormItems(formInfos)
  }, [formInfos])

  const handleAllValidate = useCallback(
    async (processValidate: boolean) => {
      if (processValidate) {
        //        let newInternalCapacityErrorTexts = [...internalCapacityErrorTexts]
        let newSupplierCategoryErrorTexts = [...supplierCategoryErrorTexts]
        let newSupplierCompanyNameErrorTexts = [
          ...supplierCompanyNameErrorTexts,
        ]
        let newSupplierBranchNameErrorTexts = [...supplierBranchNameErrorTexts]
        let newCostPriceErrorTexts = [...costPriceErrorTexts]
        let newTaxErrorTexts = [...taxErrorTexts]
        await Promise.all(
          formInfos.map(async (item, index) => {
            // newInternalCapacityErrorTexts = handleValidateInternalCapacity(
            //   index,
            //   item.internalCapacity || item.internalCapacityManualInput,
            //   newInternalCapacityErrorTexts,
            // )
            newSupplierCategoryErrorTexts = handleValidateSupplierCategory(
              index,
              item.supplierCategory,
              newSupplierCategoryErrorTexts,
            )
            newSupplierCompanyNameErrorTexts =
              handleValidateSupplierCompanyName(
                index,
                item.supplierCompanyName,
                newSupplierCompanyNameErrorTexts,
              )
            newSupplierBranchNameErrorTexts = handleValidateSupplierBranchName(
              index,
              item.supplierBranchName,
              newSupplierBranchNameErrorTexts,
            )
            newCostPriceErrorTexts = handleValidateCostPrice(
              index,
              item.costPrice,
              newCostPriceErrorTexts,
            )
            newTaxErrorTexts = handleValidateTax(
              index,
              item.tax,
              newTaxErrorTexts,
            )
          }),
        )
        handleProcessSupplierInfoValidate(false)
      }
    },
    [processValidate],
  )

  useEffect(() => {
    handleAllValidate(processValidate)
  }, [processValidate])

  return (
    <div>
      {formItems.map((item, index) => (
        <div key={index}>
          {index > 0 ? <div className={styles.addform_space}></div> : ''}
          <div className={styles.addform}>
            <div className={styles.addform_form_center}>
              <RadioButton
                label="原価計算に使用する"
                isChecked={item.usedCosting}
                size="small_bold"
                width="small"
                onChange={() => handleUsedCosting(index, !item.usedCosting)}
              />
              <div
                className={
                  item.usedCosting
                    ? styles.addform_supplier_box_check
                    : styles.addform_supplier_box
                }
              >
                <div className={styles.addform}>
                  <div className={styles.addform_form}>
                    <InputTextWithLabel
                      required={true}
                      labelName="内容量"
                      description=""
                      line={2}
                      type="text"
                      size="small"
                      labelSize="large"
                      value={String(item.internalCapacityCount)}
                      placeholder=""
                      errorText={internalCapacityCountErrorTexts[index]}
                      onChange={(event) =>
                        handleInternalCapacityCount(index, event.target.value)
                      }
                      onBlur={(event) =>
                        handleValidateInternalCapacityCount(
                          index,
                          event.target.value,
                        )
                      }
                    />
                    <SelectBox
                      style={{
                        width: 90,
                        height: 54,
                        padding: '10px 20px',
                        marginLeft: 14,
                      }}
                      size="large2"
                      value={item.internalCapacityUnit}
                      options={[
                        {
                          is_default: true,
                          label: 'g',
                          value: 'g',
                        },
                        {
                          is_default: false,
                          label: 'kg',
                          value: 'kg',
                        },
                        {
                          is_default: false,
                          label: 'mL',
                          value: 'mL',
                        },
                        {
                          is_default: false,
                          label: 'L',
                          value: 'L',
                        },
                      ]}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        handleInternalCapacityUnit(index, event.target.value)
                      }
                    />
                    {index > 0 ? (
                      <div className={styles.addform_delete_button_2}>
                        <SubmitButton
                          label=""
                          color="white"
                          size="icon"
                          icon="delete_orange"
                          onClick={() => handleDeleteButton(index)}
                        />
                      </div>
                    ) : (
                      <div className={styles.addform_space_box_icon}></div>
                    )}
                  </div>
                </div>

                <div className={styles.addform}>
                  <div className={styles.addform_form}>
                    <InputTextWithLabel
                      required={true}
                      labelName="入数"
                      description=""
                      line={2}
                      type="text"
                      size="small"
                      labelSize="large"
                      value={item.numberItem}
                      placeholder=""
                      onChange={(event) =>
                        handleNumberItem(index, event.target.value)
                      }
                    />
                    <div
                      style={{
                        marginLeft: 14,
                      }}
                    ></div>
                    <InputText
                      required={false}
                      type="text"
                      size="small"
                      value={item.numberItemUnit}
                      placeholder="個"
                      rightAligned={false}
                      onChange={(event) =>
                        handleNumberItemUnit(index, event.target.value)
                      }
                    />
                  </div>
                </div>

                <SelectBoxWithLabel
                  required={true}
                  labelName="仕入れ先カテゴリー"
                  description=""
                  line={1}
                  size="large"
                  value={item.supplierCategory}
                  errorText={supplierCategoryErrorTexts[index]}
                  labelSize="large"
                  options={[
                    {
                      is_default: true,
                      label: '----',
                      value: '',
                    },
                    {
                      is_default: false,
                      label: '問屋',
                      value: 'wholesale',
                    },
                    {
                      is_default: false,
                      label: '直販',
                      value: 'direct',
                    },
                    {
                      is_default: false,
                      label: 'その他',
                      value: 'other',
                    },
                  ]}
                  onChange={(event) =>
                    handleSupplierCategory(index, event.target.value)
                  }
                />
                <InputTextSuggestWithLabel
                  required={true}
                  labelName="仕入れ先会社名"
                  description=""
                  line={1}
                  type="text"
                  size="large"
                  value={item.supplierCompanyName}
                  placeholder=""
                  errorText={supplierCompanyNameErrorTexts[index]}
                  labelSize="large"
                  suggestType="supplier_company_name"
                  index={index}
                  onSelect={handleSupplierCompanyNameSuggest}
                  onChange={(event) =>
                    handleSupplierCompanyName(index, event.target.value)
                  }
                />
                <InputTextWithLabel
                  required={false}
                  labelName="仕入れ先支社名"
                  description=""
                  line={2}
                  type="text"
                  size="large"
                  value={item.supplierBranchName}
                  placeholder=""
                  errorText={supplierBranchNameErrorTexts[index]}
                  labelSize="large"
                  onChange={(event) =>
                    handleSupplierBranchName(index, event.target.value)
                  }
                  onBlur={(event) =>
                    handleValidateSupplierBranchName(
                      index,
                      event.target.value,
                      supplierBranchNameErrorTexts,
                    )
                  }
                />
                <InputTextAndUnitWithLabel
                  required={true}
                  labelName="仕入れ値(税抜)"
                  description=""
                  line={2}
                  type="text"
                  size="middle"
                  value={item.costPrice}
                  placeholder=""
                  unit="円"
                  errorText={costPriceErrorTexts[index]}
                  labelSize="large"
                  onChange={(event) =>
                    handleCostPrice(index, event.target.value)
                  }
                  onBlur={(event) =>
                    handleValidateCostPrice(
                      index,
                      event.target.value,
                      costPriceErrorTexts,
                    )
                  }
                />
                <div className={styles.addform}>
                  <div className={styles.addform_form}>
                    <InputTextAndUnitWithLabel
                      required={false}
                      labelName="税込金額から計算"
                      description=""
                      line={2}
                      type="text"
                      size="semimiddle"
                      value={
                        item.taxIncludedCostPrice
                          ? item.taxIncludedCostPrice
                          : ''
                      }
                      placeholder=""
                      unit="円"
                      errorText={costPriceErrorTexts[index]}
                      labelSize="large"
                      onChange={(event) =>
                        handleTaxIncludedCostPrice(index, event.target.value)
                      }
                      onBlur={(event) =>
                        handleValidateCostPrice(
                          index,
                          event.target.value,
                          costPriceErrorTexts,
                        )
                      }
                    />
                    <div
                      style={{
                        marginLeft: 36,
                      }}
                    ></div>
                    <SubmitButton
                      label="反映する"
                      color="light_gray"
                      size="rectangle_small"
                      icon="none"
                      onClick={() =>
                        handleTaxIncludedCostPriceToCostPrice(
                          index,
                          item.taxIncludedCostPrice,
                        )
                      }
                    />
                  </div>
                </div>
                <RadioButtonWithLabel
                  required={false}
                  labelName="仕入れ値税率"
                  description=""
                  line={1}
                  radioSize="large"
                  labelSize="large"
                  checkInfos={[
                    {
                      isChecked: item.tax === 8,
                      label: '8%',
                      onChange: () => handleTax(index, 8),
                    },
                    {
                      isChecked: item.tax === 10,
                      label: '10%',
                      onChange: () => handleTax(index, 10),
                    },
                  ]}
                />
                <InputTextAndUnitWithLabel
                  required={false}
                  labelName="g単価(税込)"
                  description="※小数点第三位を四捨五入して表示しています"
                  line={2}
                  type="text"
                  size="middle"
                  value={
                    yieldRate &&
                    item.internalCapacityCount &&
                    item.numberItem &&
                    item.unitAdjustment
                      ? String(
                          (
                            (Number(item.costPrice) * (item.tax / 100 + 1)) /
                            (yieldRate / 100) /
                            Number(item.internalCapacityCount) /
                            Number(item.numberItem) /
                            item.unitAdjustment
                          ).toFixed(2),
                        )
                      : ''
                  }
                  placeholder=""
                  unit="円"
                  labelSize="large"
                  readOnly={true}
                />
                <InputTextAndUnitWithLabel
                  required={false}
                  labelName="kg単価(税込)"
                  description="※小数点第三位を四捨五入して表示しています"
                  line={2}
                  type="text"
                  size="middle"
                  value={
                    yieldRate &&
                    item.internalCapacityCount &&
                    item.numberItem &&
                    item.unitAdjustment
                      ? String(
                          (
                            ((Number(item.costPrice) * (item.tax / 100 + 1)) /
                              (yieldRate / 100) /
                              Number(item.internalCapacityCount) /
                              Number(item.numberItem) /
                              item.unitAdjustment) *
                            1000
                          ).toFixed(2),
                        )
                      : ''
                  }
                  placeholder=""
                  unit="円"
                  labelSize="large"
                  readOnly={true}
                />
                <TextAreaWithLabel
                  required={false}
                  labelName="メモ"
                  description=""
                  labelPosition="top"
                  line={1}
                  maxLength={1000}
                  rows={8}
                  size="large"
                  value={item.memo}
                  placeholder="ここにメモを記入できます。"
                  onChange={(event) => handleMemo(index, event.target.value)}
                />
              </div>
              {index > 0 ? (
                <div className={styles.addform_delete_button_2}>
                  <SubmitButton
                    label=""
                    color="white"
                    size="icon"
                    icon="delete_orange"
                    onClick={() => handleDeleteButton(index)}
                  />
                </div>
              ) : (
                <div className={styles.addform_space_box_icon}></div>
              )}
            </div>
          </div>
        </div>
      ))}

      <div className={styles.addform_add_button_2}>
        <SubmitButton
          label="項目を増やす"
          color="white2"
          size="small"
          icon="outline_plus"
          onClick={handleAddButton}
        />
      </div>
    </div>
  )
}

export default AddFormSupplierInformation
