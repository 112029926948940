import { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from 'styles/components/template.module.scss'
import { DataType } from 'reducks/business/slice'
import InputText from 'components/Form/InputText'
import SubmitButton from 'components/Button/SubmitButton'
import InputTextSuggestWithLightLabel, {
  suggestInfo,
} from 'components/Form/WithLightLabel/InputTextSuggest'
import { getMaterialById } from 'reducks/material/slice'
import {
  getMaterialCostByMaterialId,
  SupplierType,
} from 'reducks/materialCost/slice'
import { BreadDetailPetternInformationType } from 'reducks/business/slice'
import { RootState } from 'reducks/reducers'

type Props = {
  maker: string
  name: string
  middlePercent: string
  middlePercentUnit: string
  mainPercent: string
  mainPercentUnit: string
  allPercent: string
  allPercentUnit: string
  unitPrice: string | null
  unitPriceUnit: string | null
  costPrice: string | null
  costPriceUnit: string | null
  isHeader: boolean
  isBold: boolean
  isParagraphDown: boolean
  mode: 'primary' | 'secondary' | undefined
  nameMinusWidth?: number
  underLine?: 'gray' | 'orange' | undefined
  handleEditRecord?: (
    dataType: DataType | undefined,
    key:
      | 'productName'
      | 'manufacturerName'
      | 'materialId'
      | 'middleKneadPercent'
      | 'authenticKneadPercent'
      | 'supplierCostPrice'
      | 'supplierTax'
      | 'internalCapacityMemo'
      | 'yieldRate'
      | 'internalCapacityCount'
      | 'internalCapacityUnit'
      | 'numberItem'
      | 'numberItemUnit'
      | 'unitPrice1g'
      | 'cost',
    value: any,
    params: (number | DataType | boolean)[] | undefined,
    inputPetternInfos?: BreadDetailPetternInformationType[] | null,
  ) => BreadDetailPetternInformationType[]
  handleDeleteButton?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  handleIsPreview?: (
    dataType: DataType | undefined,
    params: (number | DataType | boolean)[] | undefined,
  ) => void
  dataType?: DataType
  params?: (number | DataType | boolean)[]
  nameReadOnly?: boolean
  unDeletable?: boolean
  unitPriceInvisible?: boolean
}

const BreadDetailInputTextRow: FC<Props> = (props) => {
  const {
    maker,
    name,
    middlePercent,
    middlePercentUnit,
    mainPercent,
    mainPercentUnit,
    allPercent,
    allPercentUnit,
    unitPrice,
    unitPriceUnit,
    costPrice,
    costPriceUnit,
    isHeader,
    isBold,
    isParagraphDown,
    mode,
    nameMinusWidth,
    underLine,
    handleEditRecord,
    handleDeleteButton,
    handleIsPreview,
    dataType,
    params,
    nameReadOnly,
    unDeletable,
    unitPriceInvisible,
  } = props

  const dispatch = useDispatch<any>()
  const { staff } = useSelector((state: RootState) => state.staffSlice)

  const handleSuggest = async (info: suggestInfo) => {
    if (handleEditRecord) {
      const materialInfo = await dispatch(getMaterialById({ id: info.id }))
      if (materialInfo && materialInfo.payload && params) {
        let newParams = [...params]
        let inputPetternInfos = handleEditRecord(
          dataType,
          'productName',
          materialInfo.payload.product_name,
          newParams,
        )
        newParams = [...params]
        inputPetternInfos = handleEditRecord(
          dataType,
          'manufacturerName',
          materialInfo.payload.manufacturer,
          newParams,
          inputPetternInfos,
        )
        newParams = [...params]
        inputPetternInfos = handleEditRecord(
          dataType,
          'materialId',
          materialInfo.payload.id,
          newParams,
          inputPetternInfos,
        )
        const materialCostInfo = await dispatch(
          getMaterialCostByMaterialId({ id: info.id }),
        )
        if (materialCostInfo && materialCostInfo.payload && params) {
          const useSupplierInfo: SupplierType =
            materialCostInfo.payload.suppliers.filter(
              (supplier: SupplierType) => supplier.used_costing,
            )[0]
          let newParams = [...params]
          inputPetternInfos = handleEditRecord(
            dataType,
            'supplierCostPrice',
            useSupplierInfo.cost_price,
            newParams,
            inputPetternInfos,
          )
          newParams = [...params]
          inputPetternInfos = handleEditRecord(
            dataType,
            'supplierTax',
            useSupplierInfo.tax,
            newParams,
            inputPetternInfos,
          )

          const internalCapacityCount = useSupplierInfo.internal_capacity
            ? useSupplierInfo.internal_capacity.internal_capacity_count
            : useSupplierInfo.internal_capacity_count
          newParams = [...params]
          inputPetternInfos = handleEditRecord(
            dataType,
            'internalCapacityCount',
            internalCapacityCount,
            newParams,
            inputPetternInfos,
          )
          const internalCapacityUnit = useSupplierInfo.internal_capacity
            ? useSupplierInfo.internal_capacity.internal_capacity_unit
            : useSupplierInfo.internal_capacity_unit
          newParams = [...params]
          inputPetternInfos = handleEditRecord(
            dataType,
            'internalCapacityUnit',
            internalCapacityUnit,
            newParams,
            inputPetternInfos,
          )
          const numberItem = useSupplierInfo.internal_capacity
            ? useSupplierInfo.internal_capacity.number_item
            : useSupplierInfo.number_item
          newParams = [...params]
          inputPetternInfos = handleEditRecord(
            dataType,
            'numberItem',
            numberItem,
            newParams,
            inputPetternInfos,
          )
          const numberItemUnit = useSupplierInfo.internal_capacity
            ? useSupplierInfo.internal_capacity.number_item_unit
            : useSupplierInfo.number_item_unit
          newParams = [...params]
          inputPetternInfos = handleEditRecord(
            dataType,
            'numberItemUnit',
            numberItemUnit,
            newParams,
            inputPetternInfos,
          )
          newParams = [...params]
          inputPetternInfos = handleEditRecord(
            dataType,
            'yieldRate',
            materialCostInfo.payload.yield_rate,
            newParams,
            inputPetternInfos,
          )
          let unitAdjustment = 1
          if (internalCapacityUnit === 'kg') {
            unitAdjustment = 1000
          } else if (internalCapacityUnit === 'mL') {
            unitAdjustment = 1
          } else if (internalCapacityUnit === 'L') {
            unitAdjustment = 1000
          }
          console.log(
            20240915998,
            inputPetternInfos,
            useSupplierInfo,
            materialCostInfo,
            internalCapacityCount,
            numberItem,
            unitAdjustment,
          )
          const unitPrice1g =
            Number(useSupplierInfo.cost_price) ||
            Number(useSupplierInfo.cost_price) === 0
              ? (Number(useSupplierInfo.cost_price) *
                  (Number(useSupplierInfo.tax) / 100 + 1)) /
                (Number(materialCostInfo.payload.yield_rate) / 100) /
                Number(internalCapacityCount) /
                Number(numberItem) /
                unitAdjustment
              : null
          newParams = [...params]
          handleEditRecord(
            dataType,
            'unitPrice1g',
            unitPrice1g,
            newParams,
            inputPetternInfos,
          )
        }
      }
    }
  }

  const editKneadPercent = async (
    key: 'middleKneadPercent' | 'authenticKneadPercent',
    value: string,
  ) => {
    if (handleEditRecord) {
      handleEditRecord(dataType, key, value, params)
    }
  }

  return (
    <div
      className={`${styles.bread_detail_table_row} ${
        isHeader ? styles.bread_detail_table_row_gray : ''
      } ${
        underLine === 'gray'
          ? styles.bread_detail_table_row_under_line_gray
          : underLine === 'orange'
          ? styles.bread_detail_table_row_under_line_orange
          : ''
      }`}
    >
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head_bold
            : isBold
            ? styles.bread_detail_table_row_body_bold
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_name} ${
          isHeader
            ? ''
            : isBold && mode === 'secondary'
            ? ''
            : mode === 'primary'
            ? styles.bread_detail_table_row_right_border_gray
            : mode === 'secondary'
            ? styles.bread_detail_table_row_right_border_orange
            : ''
        } ${
          !isHeader && isParagraphDown && mode === 'primary'
            ? styles.bread_detail_table_row_paragraph_down
            : ''
        }`}
        style={{ width: 388 - Number(nameMinusWidth) }}
      >
        <InputTextSuggestWithLightLabel
          labelName=""
          description=""
          line={1}
          type="text"
          size="semimiddle_detail_row"
          value={name}
          placeholder=""
          margin="none"
          suggestType="material_used_product_name"
          onSelect={handleSuggest}
          onChange={(e) =>
            handleEditRecord
              ? handleEditRecord(
                  dataType,
                  'productName',
                  e.target.value,
                  params,
                )
              : {}
          }
          readOnly={nameReadOnly}
        />
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_maker} ${
          styles.bread_detail_table_row_margin_left
        }`}
      >
        <InputText
          required={false}
          type="text"
          size="xsmall_detail_row"
          value={maker}
          placeholder=""
          rightAligned={false}
          onChange={(e) =>
            handleEditRecord
              ? handleEditRecord(
                  dataType,
                  'manufacturerName',
                  e.target.value,
                  params,
                )
              : {}
          }
          readOnly={nameReadOnly}
        />
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_percent} ${
          styles.bread_detail_table_row_margin_right
        }`}
      >
        <InputText
          required={false}
          type="text"
          size="minimum_detail_row"
          value={middlePercent}
          placeholder=""
          rightAligned={false}
          onChange={(e) =>
            editKneadPercent('middleKneadPercent', e.target.value)
          }
        />
        <div className={styles.bread_detail_table_input_and_unit}>
          <span>{middlePercentUnit}</span>
        </div>
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_percent} ${
          styles.bread_detail_table_row_margin_right
        } ${
          isHeader || (isBold && mode === 'secondary')
            ? styles.bread_detail_table_row_right_border_white
            : mode === 'primary'
            ? styles.bread_detail_table_row_right_border_gray
            : mode === 'secondary'
            ? styles.bread_detail_table_row_right_border_orange
            : ''
        }`}
      >
        <InputText
          required={false}
          type="text"
          size="minimum_detail_row"
          value={mainPercent}
          placeholder=""
          rightAligned={false}
          onChange={(e) =>
            editKneadPercent('authenticKneadPercent', e.target.value)
          }
        />
        <div className={styles.bread_detail_table_input_and_unit}>
          <span>{mainPercentUnit}</span>
        </div>
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head_bold
            : styles.bread_detail_table_row_body_bold
        } ${styles.bread_detail_table_row_percent} ${
          styles.bread_detail_table_row_margin_right
        } ${
          isHeader || (isBold && mode === 'secondary')
            ? styles.bread_detail_table_row_right_border_white
            : mode === 'primary'
            ? styles.bread_detail_table_row_right_border_gray
            : mode === 'secondary'
            ? styles.bread_detail_table_row_right_border_orange
            : ''
        }`}
      >
        <InputText
          required={false}
          type="text"
          size="minimum_detail_row"
          value={allPercent}
          placeholder=""
          rightAligned={false}
          readOnly={true}
        />
        <div className={styles.bread_detail_table_input_and_unit}>
          <span>{allPercentUnit}</span>
        </div>
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_price}`}
      >
        {/* <div className={styles.bread_detail_table_row_click_box}>
        {unitPrice ? unitPrice : ''}
        </div> */}
        {unitPriceInvisible ? (
          ''
        ) : (
          <>
            <InputText
              required={false}
              type="text"
              size="minimum_detail_row"
              value={unitPrice ? unitPrice : ''}
              placeholder=""
              rightAligned={false}
              onFocus={() =>
                handleIsPreview ? handleIsPreview(dataType, params) : {}
              }
            />
            <div className={styles.bread_detail_table_input_and_unit}>
              <span>{unitPriceUnit}</span>
            </div>
          </>
        )}
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head_bold
            : isBold
            ? styles.bread_detail_table_row_body_bold
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_price}`}
      >
        <InputText
          required={false}
          type="text"
          size="minimum_detail_row"
          value={costPrice ? costPrice : ''}
          placeholder=""
          rightAligned={false}
          readOnly={true}
        />
        <div className={styles.bread_detail_table_input_and_unit}>
          <span>{costPriceUnit}</span>
        </div>
      </div>
      {/* <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_action}`}
      >
        {((staff?.isAdmin === false && staff.isStandardPlan === true) ||
          (staff?.isAdmin === true && adminShopId)) &&
        isHeader ? (
          '原価'
        ) : ((staff?.isAdmin === false && staff.isStandardPlan === true) ||
            (staff?.isAdmin === true && adminShopId)) &&
          costEditTo ? (
          <SubmitButton
            label=""
            color="white"
            size="icon"
            icon="cost_input_gray"
            onClick={() => handleSubmit(true)}
          />
        ) : (
          ''
        )}
      </div>
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_action}`}
      >
        {staff?.isAdmin && isHeader ? (
          '編集'
        ) : staff?.isAdmin && editTo ? (
          <SubmitButton
            label=""
            color="white"
            size="icon"
            icon="edit_gray"
            onClick={() => handleSubmit()}
          />
        ) : (
          ''
        )}
      </div> */}
      <div
        className={`${
          isHeader
            ? styles.bread_detail_table_row_head
            : styles.bread_detail_table_row_body
        } ${styles.bread_detail_table_row_action}`}
      >
        {staff?.isAdmin && isHeader ? (
          '削除'
        ) : unDeletable ? (
          ''
        ) : (
          <SubmitButton
            label=""
            color="white"
            size="icon"
            icon="delete_gray"
            onClick={() =>
              handleDeleteButton ? handleDeleteButton(dataType, params) : {}
            }
          />
        )}
      </div>
    </div>
  )
}

export default BreadDetailInputTextRow
